/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

// @use "@angular/material" as mat;

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
// @include mat.all-legacy-component-typographies();
// @include mat.legacy-core();

// $app-primary: mat.define-palette(mat.$yellow-palette);
// $app-accent: mat.define-palette(mat.$indigo-palette, A200, A100, A400);

// $app-theme: mat.define-light-theme($app-primary, $app-accent);

// @mixin mix-app-theme($app-theme) {
//   $primary: map-get($app-theme, primary);
//   $accent: map-get($app-theme, accent);

//   .mat-button-toggle {
//     background-color: mat.get-color-from-palette($primary);
//     color: mat.get-color-from-palette($primary, default-contrast);
//   }

//   .mat-button-toggle-checked {
//     background-color: mat.get-color-from-palette($accent);
//     color: mat.get-color-from-palette($accent, default-contrast);
//   }
// }

// Include the mixin
// @include mix-app-theme($app-theme);

// Fix .action-sheet IOS
.action-sheet-group {
  overflow: auto !important;
}

.selected {
  --border-width: 1.5px !important;
  --border-color: #007cb0 !important;
  --border-radius: 7px !important;
  --background: #dbf4ff !important;
  font-weight: 500 !important;
}

.centralizeTotal {
  margin: 0 !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  margin-right: -50% !important;
  transform: translate(-50%, -50%) !important;
}

.none {
  display: none !important;
}

.text {
  font-weight: 260;
  margin: 5px 0px;
}

// Modal aberto quando clicamos em uma matrícula
// ele contém as informações do serviço
.modalInfoCar .modal-wrapper {
  height: 60vh;
  top: 40vh;
  position: absolute;
  display: block;
}

// Modal das definições em ganhosPage
.modalConfig .modal-wrapper {
  height: 250px;
  width: 60vw;
  top: 20%;
  position: absolute;
  display: block;
}

// Definições do @capacitor-community/barcode-scanner
.scanner-ui {
  display: none;
}
.scanner-hide {
  visibility: visible;
}
body.qrscanner {
  background-color: transparent;
}
body.qrscanner .scanner-ui {
  display: block;
}
body.qrscanner .scanner-hide {
  visibility: hidden;
}
